<template>
<div>
    <div class="sub_container">
      <div class="sub_wrapper">
        <h2 class="sub-title1">{{ $t('menu5-list') }}</h2>
        <el-card class="box-card">
          {{  $t('menu5-context')}}
        </el-card>
        <Stable :columnes="columnes_data"
                :tableData="tableData_data"
                :total_data = "total_data"
                :loading="loading"
                 @handleCurrentChange="handleCurrentChange"></Stable>
      </div>
    </div>

</div>
</template>
<script>
import Stable from '@/components/Stable.vue';

export default {
  name: 'Accounts',
  components: {
    Stable,
  },
  data() {
    return {
      loading: true,
      columnes_data: [{ label: 'columnAddress', prop: 'date' },
        { label: 'columnAvailable', prop: 'name' },
        { label: 'columnTxs', prop: 'txs' },
        { label: 'columnTime', prop: 'time' }],
      tableData_data: [],
      total_data: 1,
    };
  },
  methods: {
    detailClick() {
      this.$router.push({
        // name: 'accountDetail', params: { me: row.block_hash },
        name: 'accountDetail', params: { me: 'cosmos1429702s2j7n98l7w4ucjf8ys4tqhtmxpqc9ath' },
      });
    },
    handleCurrentChange(val) {
      this.loading = true;
      this.getData(val);
    },
    getData(page) {
      this.$axios.get(`/blocks?page_no=${page}&list_size=10`)
        .then((res) => {
          if (res.data.ret_code === 200) {
            this.total_data = res.data.data.block_tot;
            this.tableData_data = res.data.data.blocks;
          }
        })
        .catch((error) => { this.$message.error(error); })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getData(1);
  },
};
</script>
